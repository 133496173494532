@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot?tuanrl');
  src: url('../fonts/icomoon/icomoon.eot?tuanrl#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?tuanrl') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?tuanrl') format('woff'),
    url('../fonts/icomoon/icomoon.svg?tuanrl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i[class^="am-"],
i[class*=" am-"] {
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.am-shop:before {
  content: "\e907";
}

.am-exit:before {
  content: "\e906";
}

.am-asign-funds:before {
  content: "\e900";
}

.am-legal-entity:before {
  content: "\e901";
}

.am-transactions:before {
  content: "\e902";
}

.am-user-management:before {
  content: "\e903";
}

.am-reports:before {
  content: "\e904";
}

.am-home:before {
  content: "\e905";
}


// i.am {
//   display: inline-block;
//   width: 30px;
//   height: 30px;
// }

// .am-home {
//   height: 30px !important;
//   width: 30px !important;
//   background: url('../icons/gray/home.svg');
// }

// .am-home.am-primary {
//   background: url('../icons/primary/home.svg');
// }

// .am-clients {
//   height: 27px !important;
//   width: 32px !important;
//   background: url('../icons/gray/clients.svg');
// }

// .am-clients.am-primary {
//   background: url('../icons/primary/clients.svg');
// }

// .am-asign-funds {
//   height: 30px !important;
//   width: 31px !important;
//   background: url('../icons/gray/asign-funds.svg');
// }

// .am-asign-funds.am-primary {
//   background: url('../icons/primary/asign-funds.svg');
// }

// .am-transactions {
//   height: 34px !important;
//   width: 34px !important;
//   background: url('../icons/gray/transactions.svg');
// }

// .am-transactions.am-primary {
//   background: url('../icons/primary/transactions.svg');
// }

// .am-billings {
//   height: 35px !important;
//   width: 27px !important;
//   background: url('../icons/gray/billings.svg');
// }

// .am-billings.am-primary {
//   background: url('../icons/primary/billings.svg');
// }

// .am-reports {
//   height: 38px !important;
//   width: 38px !important;
//   background: url('../icons/gray/reports.svg');
// }

// .am-reports.am-primary {
//   background: url('../icons/primary/reports.svg');
// }

// .am-config {
//   height: 32px !important;
//   width: 32px !important;
//   background: url('../icons/gray/config.svg');
// }

// .am-config.am-primary {
//   background: url('../icons/primary/config.svg');
// }

// .am-exit {
//   height: 24px !important;
//   width: 24px !important;
//   background: url('../icons/exit.svg');
// }
