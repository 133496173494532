@import 'variables.scss';

.btn {
  height: 56px;
  border-radius: 10px;
  font-weight: bold;
}

.btn-primary {
  background-color: $primary;
  color: white;
  border-color: $primary;
  box-shadow: none;
}

button.btn-primary:hover {
  border-color: $primary;
  background-color: $primary;
  filter: brightness(0.95);
}

.btn-dark {
  background-color: $dark;
  color: white;
  border-color: $dark;
  box-shadow: none;
}

button.btn-dark:hover {
  border-color: $dark;
  background-color: $dark;
  filter: brightness(0.9);
}

button.btn-outline-primary {
  color: $primary !important;
  border-color: $primary !important;
}

button.btn-outline-primary:hover {
  color: white !important;
  border-color: $primary !important;
  background-color: $primary !important;
}

button.btn-outline-white {
  background-color: white !important;
  border-color: #DBDBDB !important;
}

.btn-block {
  width: 100%;
}

.btn-circle {
  min-height: 56px !important;
  min-width: 56px !important;
  border-radius: 100px;
}

.btn-md {
  font-size: 14px;
  height: 48px;
}

button.btn-outline-dark {
  color: $dark !important;
  border-color: $dark !important;
}

button.btn-outline-dark:hover {
  color: white !important;
  border-color: $dark !important;
  background-color: $dark !important;
}
