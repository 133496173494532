$enable-negative-margins: true;

@use "assets/scss/fonts.scss";
@use "assets/scss/texts.scss";
@use "assets/scss/buttons.scss";
@use "assets/scss/icons.scss";
@import "assets/scss/variables.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~quill/dist/quill.bubble.css";
.page-item.active .page-link {
  z-index: 3;
  color: #484848;
  background-color: #f9f9ff;
  border-color: #dee2e6;
}

.page-link {
  color: #484848;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.09);
  border: 0.5px solid #aac1ce;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.only-top-border {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

body {
  font-family: "Inter";
  color: $text-color;
  background-color: #ffffff;
  height: 100%;
}

label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 11px;
}

input.form-control {
  border-radius: 10px;
  height: 56px;
  padding-left: 23px;
  padding-right: 23px;
}

input.form-control::placeholder {
  font-size: 14px;
  color: #bfbfbf;
}

select.form-select {
  border-radius: 10px;
}

select.select-gray {
  background-color: #f9f9f9;
  font-size: 12px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bg-blue {
  background-color: #eef9ff !important;
}

.bg-purple {
  background-color: #f5f7fd !important;
}

.bg-orange {
  background-color: #fff6f5 !important;
}

.bg-lavender {
  background-color: #dce2ff !important;
}

.entity-name {
  color: #9482b2;
}

.bg-gray {
  background-color: #fafbff !important;
}

.am-table {
  font-size: 12px;
  white-space: nowrap !important;

  th {
    font-size: 14px;
  }

  td:first-child {
    padding-left: 1.5rem;
  }

  tr:last-child {
    border-bottom: 0px solid transparent;
  }

  th:first-child {
    padding-left: 1.5rem;
  }

  td {
    vertical-align: middle;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    border-bottom: 1px solid #dbdbdb;
  }

  tbody tr {
    &:hover {
      background-color: #f9f9ff;
    }
  }
}

img-title {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: grey;
}

.paginator {
  font-weight: 600;
  font-size: 14px;
  line-height: 126.8%;
}
.page-link {
  color: #c2c2c2 !important;
}

.page-item.active .page-link {
  font-weight: 600;
  color: #484848 !important;
  background: #f9f9ff;
}

.paginator-text {
  font-size: 13px;
  line-height: 146.3%;
  color: #5b5b5b;
}

.card {
  border-color: #dbdbdb;
  box-shadow: none;
}

.mt-0 {
  margin-top: 0.4rem;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 126.8% !important;
  color: #484848 !important;
}

.padding-custom-left {
  padding-left: 1.8rem !important;
}

.padding-custom-right {
  padding-right: 2.8rem !important;
}

.padding-custom-right-pre {
  padding-right: 1.8rem !important;
}
.pm-table {
  font-size: 12px;
  border: 1px solid #dbdbdb !important;
  border-radius: 2%;
  th {
    font-size: 14px;
  }

  td:first-child {
    padding-left: 1.5rem;
  }

  tr:last-child {
    border-bottom: 0px solid transparent;
  }

  th:first-child {
    padding-left: 1.5rem;
  }

  td {
    vertical-align: middle;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #dbdbdb;
  }
}

.badge-green {
  background-color: #f0fceb;
  color: #108c93;
  padding: 5px 10px 5px 10px;
  border-radius: 100px;
}

.divider {
  border-top: 1px solid #dbdbdb !important;
}

select.form-select {
  border-radius: 14px;
}

select.form-select-lg {
  height: 56px;
  font-size: 14px;
}

.navbar-spacing {
  height: 6rem;
}

.w-0 {
  width: 0 !important;
}

.mx-n3 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.mt-n4 {
  margin-top: -1.5rem;
}

.bold {
  font-weight: bold !important;
}

.btn-white {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  background: white;
  color: #343434;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .mx-lg-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}

.modal-expanded {
  min-height: 100vh !important;
  height: auto;
  margin: 0 0 0 auto;

  .modal-content {
    height: 100%;
    min-height: 100vh !important;
    height: auto;
  }
}

.modal-expanded-center {
  min-height: 100vh !important;
  height: auto;

  .modal-content {
    height: 100%;
    min-height: 100vh !important;
    height: auto;
  }
}

.modal-content-centered {
  .modal-content {
    align-items: center;
    justify-content: center;
  }
}

.modal-content {
  border-radius: 10px !important;
  background-color: #f6f6f6;
}

.circle {
  display: flex;
  border-radius: 1000px;
  background-color: #ececec;
  color: #c7c7c7;
  height: 8vh;
  width: 8vh;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.form-switch input.form-check-input {
  height: 30px;
  width: 46px !important;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(168, 51, 58,1.0)'/></svg>") !important;
  background-color: #f7f7f7;
  border: none;
  &:checked {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(34,  138, 234,1.0)'/></svg>") !important;
    border: none;
  }
}

input.form-check-input {
  width: 22px;
  height: 22px;
}

.form-select-md,
.form-control-md {
  height: 48px !important;
}

.bg-svg {
  background-image: url("assets/svg/background.svg");
  background-size: cover;
}

.dropdown-item:hover {
  background-color: #ececff;
  color: #484848;
  border-radius: 30px;
  cursor: pointer;
}

.dropdown-item {
  color: #484848;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 0px;
  text-align: center;
}

.dropdown-menu {
  padding: 5px !important;
}

select {
  text-overflow: ellipsis;
}

.close-btn {
  right: 0;
  top: 0;
}

.image-profile {
  object-fit: cover;
  border-radius: 1000px;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-backdrop {
  background-color: #222222 !important;
  height: 100%;
  width: 100%;
}
.modal-backdrop.show {
  opacity: 0.9 !important;
}

.modal-right {
  max-width: 678px;
}

.pac-container{
  z-index: 9999;
}

.table tr{
  border-bottom: 1px solid #dbdbdb !important;
}

.dollar-position{
  position: absolute;
  font-weight: normal;
  padding-bottom: 0.3rem;
}
