@import 'variables.scss';

.extrabold {
  font-weight: 800 !important;
}

.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

.text-primary {
  color: $primary !important;
}

.text-light-gray {
  color: $light-gray !important;
}

.text-gray {
  color: $gray !important;
}

.text-default {
  color: $text-color !important;
}

.text-blue {
  color: $blue !important;
}

.text-purple {
  color: $purple !important;
}

.text-orange {
  color: $orange !important;
}

.title {
  font-weight: 800;
  font-size: 30px;
}

.text-size-12px {
  font-size: 13px;
}

.text-size-13px {
  font-size: 13px;
}

.text-size-14px {
  font-size: 14px;
}

.text-size-15px {
  font-size: 15px;
}

.text-size-18px {
  font-size: 18px;
}

.text-size-20px {
  font-size: 20px;
}

.text-size-21px {
  font-size: 21px;
}

.text-size-24px {
  font-size: 24px;
}

.text-size-26px {
  font-size: 26px;
}

.text-size-28px {
  font-size: 28px;
}

.text-size-35px {
  font-size: 35px;
}

.text-size-40px {
  font-size: 40px;
}

h1 {
  font-size: 30px;
  font-weight: 800;
}

h3 {
  font-size: 20px;
  font-weight: 800;
}
